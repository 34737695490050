import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full'
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./pages/main/main.module').then( m => m.MainPageModule)
  },
  {
    path: 'hotels',
    loadChildren: () => import('./pages/hotels/hotels.module').then( m => m.HotelsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'calls',
    loadChildren: () => import('./pages/calls/calls.module').then( m => m.CallsPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'mgb',
    loadChildren: () => import('./pages/mgb/mgb.module').then( m => m.MgbPageModule)
  },
  {
    path: 'mgb-home',
    loadChildren: () => import('./pages/mgb-home/mgb-home.module').then( m => m.MgbHomePageModule)
  },
  {
    path: 'mgb-edit',
    loadChildren: () => import('./pages/mgb-edit/mgb-edit.module').then( m => m.MgbEditPageModule)
  },
  {
    path: 'tasks',
    loadChildren: () => import('./pages/tasks/tasks.module').then( m => m.TasksPageModule)
  },
  {
    path: 'taskend',
    loadChildren: () => import('./pages/taskend/taskend.module').then( m => m.TaskendPageModule)
  },
  {
    path: 'rooms',
    loadChildren: () => import('./pages/rooms/rooms.module').then( m => m.RoomsPageModule)
  },
  {
    path: 'room-details',
    loadChildren: () => import('./pages/room-details/room-details.module').then( m => m.RoomDetailsPageModule)
  },
  {
    path: 'survey',
    loadChildren: () => import('./pages/survey/survey.module').then( m => m.SurveyPageModule)
  },
  {
    path: 'survey-home',
    loadChildren: () => import('./pages/survey-home/survey-home.module').then( m => m.SurveyHomePageModule)
  },
  {
    path: 'checklist',
    loadChildren: () => import('./pages/checklist/checklist.module').then( m => m.ChecklistPageModule)
  },
  {
    path: 'checklist-home',
    loadChildren: () => import('./pages/checklist-home/checklist-home.module').then( m => m.ChecklistHomePageModule)
  },
  {
    path: 'survey-result',
    loadChildren: () => import('./pages/survey-result/survey-result.module').then( m => m.SurveyResultPageModule)
  },
  {
    path: 'survey-list',
    loadChildren: () => import('./pages/survey-list/survey-list.module').then( m => m.SurveyListPageModule)
  },
  {
    path: 'survey-details',
    loadChildren: () => import('./pages/survey-details/survey-details.module').then( m => m.SurveyDetailsPageModule)
  },
  {
    path: 'checklist-result',
    loadChildren: () => import('./pages/checklist-result/checklist-result.module').then( m => m.ChecklistResultPageModule)
  },
  {
    path: 'security',
    loadChildren: () => import('./pages/security/security.module').then( m => m.SecurityPageModule)
  },
  {
    path: 'security-home',
    loadChildren: () => import('./pages/security-home/security-home.module').then( m => m.SecurityHomePageModule)
  },
  {
    path: 'vehicles',
    loadChildren: () => import('./pages/vehicles/vehicles.module').then( m => m.VehiclesPageModule)
  },
  {
    path: 'personel',
    loadChildren: () => import('./pages/personel/personel.module').then( m => m.PersonelPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
