import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isAuthenticated = false;
  constructor(private router: Router) {}


  checkToken() {
    const token = localStorage.getItem('token'); 

    if (!token) {
      this.isAuthenticated = false;
      this.router.navigate(['/sign-in']); 
    } else {
      this.isAuthenticated = true;
    }
  }


  removeToken() {
    localStorage.removeItem('token');
    this.isAuthenticated = false;
    this.router.navigate(['/sign-in']); 
  }

}