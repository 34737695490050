import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  LocalUser: any;
  activePageTitle = 'Dashboard';
  Pages = [
    {
      title: 'Calls',
      url: '/tabs/home',
      icon: 'alarm-outline'
    },
    {
      title: 'Mgb',
      url: '/tabs/mgb-home',
      icon: 'calendar-outline'
    },
    {
      title: 'Survey',
      url: '/tabs/survey-home',
      icon: 'pencil-outline'
    },
    {
      title: 'Checklist',
      url: '/tabs/checklist',
      icon: 'checkmark-done-outline'
    },
    {
      title: 'Lokasyon',
      url: '/tabs/rooms',
      icon: 'business-outline'
    },
    {
      title: 'Güvenlik',
      url: '/security-home',
      icon: 'car-outline'
    },
    {
      title: 'Ayarlar',
      url: '/tabs/settings',
      icon: 'settings-outline'
    },
  ];
  constructor(
    private platform: Platform,  private router: Router,  private authService: AuthService, private menuController: MenuController
  ) {
    this.initializeApp();
    this.LocalUser =  window.localStorage.getItem('user') || '';
  }
  initializeApp() {
    this.platform.ready().then(() => {
   
    });
  }

  getInitials(name: string): string {
    if (!name) {
      return '';
    }

    const initials = name
      .split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('');

    return initials;
  }

  logout() {
    this.authService.removeToken(); 
    this.menuController.close();
  }

  
}